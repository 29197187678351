import {read, utils} from 'xlsx'
import {default as axios} from "axios";
import {useEffect, useState} from "react";
import {useLocalStorage} from "@uidotdev/usehooks";

let API_BASE = 'http://localhost:3001';
if (window.location.hostname !== 'localhost') {
    API_BASE = 'https://szaniterline-xlsx.dev-szaniteronline.workers.dev/';
}
const MAX_MATCH = 100;

async function apiCall(endpoint, data = {}) {
    const res = await axios.post(`${API_BASE}${endpoint}`, data);
    return res.data;
}


function App() {
    const filesRef = useState(null);
    const searchRef = useState(null);
    const [showFiles, setShowFiles] = useState(false);
    const [logs, setLogs] = useState([]);
    const [sheetsOnServer, setSheetsOnServer] = useState([]);
    const [triggerSheetReload, setTriggerSheetReload] = useState(0);
    const [selectedSheets, setSelectedSheets] = useLocalStorage('selectedSheets', []);
    // Each item is a {name: 'sheetname', content: [{...}, {...}]}
    const [locallyLoadedData, setLocallyLoadedData] = useState([]);
    const [searchText, setSearchText] = useLocalStorage('searchText', '');
    const [selectedKeys, setSelectedKeys] = useLocalStorage('selectedKeys', []);
    const [resultsShown, setResultsShown] = useState(MAX_MATCH);

    const addLog = (log) => {
        setLogs((logs) => [...logs, `[${new Date().toISOString()}] ${log}`]);
    }

    function processExcel(filename, sheetName, data) {
        addLog(`Feldolgozás ${filename} ${sheetName}`);
        apiCall('/add', {name: `${sheetName} : ${filename}`, content: JSON.stringify(data)}).then(() => {
            addLog(`Feldolgozás ${filename} ${sheetName} kész`);
            setTriggerSheetReload(triggerSheetReload + 1);
        }).catch((err) => {
            addLog(`Feldolgozás ${filename} ${sheetName} hiba: ${err}`);
        });
    }

    function onDelete(sheet) {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm(`Biztosan törölni akarod a ${sheet} nevű fájlt?`)) {
            return;
        }
        apiCall('/delete', {name: sheet}).then(() => {
            addLog(`Törlés ${sheet} kész`);
            setTriggerSheetReload(triggerSheetReload + 1);
        }).catch((err) => {
            addLog(`Törlés ${sheet} hiba: ${err}`);
        });
    }

    useEffect(() => {
        if (!filesRef.current || !searchRef.current) {
            return;
        }
        console.log(filesRef.current, searchRef.current);
        filesRef.current.style.display = showFiles ? 'block' : 'none';
        searchRef.current.style.display = showFiles ? 'none' : 'block';
    }, [showFiles, filesRef.current, searchRef.current]);

    useEffect(() => {
        if (selectedSheets.length === 0) {
            return;
        }

        // download sheet data from the server
        apiCall('/download', {names: selectedSheets}).then((data) => {
            setLocallyLoadedData(data);
        }).catch((err) => {
            addLog(`Hiba: ${err}`);
        });
    }, [sheetsOnServer, setLocallyLoadedData]);

    useEffect(() => {
        apiCall('/list').then((sheets) => {
            setSheetsOnServer((oldSheet) => {
                if (oldSheet.length === 0) {
                    setSelectedSheets(sheets.sheets);
                }
                return sheets.sheets;
            });
        });
    }, [triggerSheetReload, setSelectedSheets]);

    function onFileUpload(e) {
        if (e.target.files.length === 0) {
            return;
        }
        [...e.target.files].forEach((file) => {
            addLog(`Feltöltés ${file.name}`);
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = (e) => {
                addLog(`Beolvasás ${file.name}`);
                const bufferArray = e?.target.result;
                const wb = read(bufferArray, {type: 'buffer'});

                wb.SheetNames.forEach((sheetName) => {
                    const wsname = wb.SheetNames[0];
                    const data = utils.sheet_to_json(wb.Sheets[sheetName]);
                    processExcel(file.name, sheetName, data);
                });
            }
        });
    }

    // do the search on locallyLoadedData
    const matches = [];
    locallyLoadedData.forEach((sheet) => {
        if (matches.length >= resultsShown) {
            return;
        }
        if (!selectedSheets.includes(sheet.name)) {
            return;
        }
        sheet.content.forEach((row) => {
            if (matches.length >= resultsShown) {
                return;
            }
            if (JSON.stringify(row).includes(searchText)) {
                matches.push({
                    sheet: sheet.name, row,
                });
            }
        });
    });

    // Grab keys from matches in order of appearance
    const matchHeaders = [];
    matches.forEach((match) => {
        Object.keys(match.row).forEach((key) => {
            if (!matchHeaders.includes(key)) {
                matchHeaders.push(key);
            }
        });
    });

    // Grab all keys from all sheets
    const allHeaders = ['Fül - Fájl'];
    locallyLoadedData.forEach((sheet) => {
        sheet.content.forEach((row) => {
            Object.keys(row).forEach((key) => {
                if (!allHeaders.includes(key)) {
                    allHeaders.push(key);
                }
            });
        });
    });


    return (<>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mt-2">
                    <div style={{display: 'flex'}}>
                        <input type="text" value={searchText} style={{float: '1 1 0', display: 'flex'}}
                               onChange={(e) => {
                                   setSearchText(e.target.value);
                               }} className="form-control form-control-lg"/>
                        <button className="btn btn-primary" style={{display: 'flex', textWrap: 'nowrap', paddingTop: 8}}
                                onClick={() => {
                                    setShowFiles((showFiles) => !showFiles);
                                }}>Mód: {showFiles ? 'Fájlok' : 'Keresés'}
                        </button>
                    </div>
                    <br/>
                    <button
                        className="btn btn-danger btn-sm"
                        style={{margin: '2px'}}
                        onClick={() => {
                            setSelectedKeys(allHeaders);
                        }}>Mindent kijelölt
                    </button>
                    <button
                        className="btn btn-danger btn-sm"
                        style={{margin: '2px'}}
                        onClick={() => {
                            setSelectedKeys([]);
                        }}>Kijelölés törlése
                    </button>
                    {allHeaders.map((header, idx) => {
                        return <button
                            key={idx}
                            className={`btn btn-sm ${selectedKeys.includes(header) ? 'btn-primary' : 'btn-secondary'}`}
                            onClick={() => {
                                setSelectedKeys((selectedKeys) => {
                                    if (selectedKeys.includes(header)) {
                                        return selectedKeys.filter((key) => key !== header);
                                    } else {
                                        return [...selectedKeys, header];
                                    }
                                })
                            }}
                            style={{margin: '2px'}}
                        >{header}</button>
                    })}
                </div>
                <div className="col-12" style={{overflowX: 'scroll', display: 'none'}} ref={searchRef}>
                    <h4>Keresés eredménye</h4>
                    <table className="table table-bordered" style={{fontSize: '80%'}}>
                        <thead>
                        <tr>
                            {selectedKeys.includes('Fül - Fájl') && <th>Fül - Fájl</th>}
                            {matchHeaders.filter((header) => selectedKeys.includes(header)).map((header, idx) => {
                                return <th key={idx}>{header}</th>
                            })}
                        </tr>
                        </thead>
                        <tbody>
                        {matches.map((match, idx) => {
                            return <tr key={idx}>
                                {selectedKeys.includes('Fül - Fájl') && <td>{match.sheet}</td>}
                                {matchHeaders.filter((header) => selectedKeys.includes(header)).map((header, idx) => {
                                    return <td
                                        {...(((`${match.row[header]}` || '').indexOf(searchText) !== -1 && searchText !== '') ? {style: {backgroundColor: 'yellow'}} : {})}
                                        key={idx}
                                    >{match.row[header] || ''}</td>
                                })}
                            </tr>
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan={matchHeaders.length + 1}>
                                Találatok
                                száma: {matches.length} {matches.length >= resultsShown &&
                                    <button className="btn btn-primary btn-sm" onClick={() => {
                                        setResultsShown(resultsShown + MAX_MATCH);
                                    }}>További {MAX_MATCH} találat betöltése</button>}
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
                <div className="col-12" ref={filesRef}>
                    <h4>Feltöltött fájlok</h4>
                    <button
                        className="btn btn-primary btn"
                        onClick={() => {
                            setSelectedSheets(sheetsOnServer);
                        }}
                    >Mindent kijelölt
                    </button>
                    {` `}
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setSelectedSheets([]);
                        }}>Kijelölés törlése
                    </button>
                    <br/>
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th>Keressünk benne</th>
                            <th>Fül - Fájl</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sheetsOnServer.map((sheet, idx) => {
                            return <tr key={idx}>
                                <td>
                                    <input type="checkbox" checked={selectedSheets.includes(sheet)} onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedSheets(sheet);
                                        } else {
                                            setSelectedSheets([]);
                                        }
                                    }}/>
                                </td>
                                <td>
                                    {sheet}
                                </td>
                                <td>
                                    <button onClick={() => {
                                        onDelete(sheet);
                                    }}>Törlés
                                    </button>
                                </td>
                            </tr>
                        })}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colspan={3}>
                                Feltöltés:
                                <form style={{display: 'inline-block'}}>
                                    <input type="file" onChange={onFileUpload} multiple/>
                                </form>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                <small>
                                    {logs.map((log, index) => (<div key={index}>{log}</div>))}
                                </small>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </>);
}

export default App;
